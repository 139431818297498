.preloader-fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Adjust duration as needed */
}

.pulse {
  animation: pulse-animation 1s infinite ease-in-out;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}